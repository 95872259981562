import _defineProperty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
import React, { Component } from 'react';
import { PageLoader } from '@yieldstreet/ui-kit';
import axios from 'axios';
import NotFound from 'sharedComponents/NotFound';
import WordpressArticle from './WordpressArticle';
class NoMatch extends Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "state", {
      notFound: false,
      articleHtml: null,
      isPrivacyOrTerms: false
    });
    _defineProperty(this, "componentDidMount", () => {
      const pathName = this.props.location.pathname.substr(1);
      axios.get(`/wp-json/wp/v2/pages?slug=${pathName}`).then(res => {
        if (res.data.length === 0) {
          this.setState({
            notFound: true
          });
          // not found, go 404
        } else {
          this.setState({
            articleHtml: res.data[0].content.rendered,
            articleTitle: res.data[0].title.rendered,
            isPrivacyOrTerms: ['privacy', 'terms', 'beneficiary-agreement'].includes(pathName)
          });
        }
      });
    });
  }
  render() {
    const {
      articleHtml,
      notFound
    } = this.state;
    if (articleHtml) {
      return __jsx(WordpressArticle, {
        articleHtml: articleHtml
      });
    }
    if (notFound) {
      return __jsx(NotFound, null);
    }
    return __jsx(PageLoader, null);
  }
}
export default NoMatch;